import React, { useState } from "react";
import { FaFacebookF, FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { subscribeEmail } from '../apis/apiService';

export default function ComingSoonPage() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return re.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); 

    if (!validateEmail(email)) {
      setError("Please enter a valid email address.");
      setIsSubmitting(false); 
      return;
    }

    try {
      const data = await subscribeEmail(email);
      setSuccess(data.message);
      setError("");
      setEmail(""); 
    } catch (err) {
      setError(err.error || "Already Subscribed, We will connect soon!");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="relative flex items-center justify-center min-h-screen bg-gray-800">
      <div className="absolute inset-0">
        <img
          src="https://images.unsplash.com/photo-1535320903710-d993d3d77d29?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt="Background"
          className="w-full h-full object-cover"
          srcSet="
            https://images.unsplash.com/photo-1535320903710-d993d3d77d29?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 400w,
            https://images.unsplash.com/photo-1535320903710-d993d3d77d29?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 800w,
            https://images.unsplash.com/photo-1535320903710-d993d3d77d29?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 1200w,
            https://images.unsplash.com/photo-1535320903710-d993d3d77d29?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D 1470w"
          sizes="(max-width: 600px) 400px, 
                 (max-width: 900px) 800px, 
                 1200px"
        />
        <div className="absolute inset-0 bg-black opacity-80 backdrop-blur-md"></div>
      </div>

      <div className="relative z-10 text-center text-white p-4">
        <div className="mb-10">
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4">Stockforecast.in</h1>
          <h5 className="text-md sm:text-lg md:text-xl font-bold">Stock market analysis and forecast platform for everyone</h5>
        </div>

        <div className="mb-8">
          <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold">WE'RE BUILDING OUR WEBSITE</h1>
          <p className="text-sm sm:text-lg mt-4">Sign up now and be the first to know when it's ready!</p>
        </div>

        <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row justify-center items-center mt-6">
          <input
            type="email"
            placeholder="Enter your email..."
            className="rounded-full px-6 py-3 text-gray-900 focus:outline-none w-64 sm:w-80 max-w-full"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button
            type="submit"
            className={`mt-4 sm:mt-0 sm:ml-2 bg-blue-500 rounded-full px-4 py-3 transition-all shadow-md hover:shadow-lg ${isSubmitting ? 'animate-pulse' : 'hover:bg-blue-700'}`}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : '➔'}
          </button>
        </form>
        {error && <p className="text-red-500 mt-4">{error}</p>}
        {success && <p className="text-green-500 mt-4">{success}</p>}

        <div className="flex justify-center space-x-4 mt-8">
          <a href="#" className="text-2xl hover:text-blue-400 transition-colors"><FaFacebookF /></a>
          <a href="#" className="text-2xl hover:text-gray-400 transition-colors"><FaXTwitter /></a>
          <a href="#" className="text-2xl hover:text-red-400 transition-colors"><FaInstagram /></a>
          <a href="#" className="text-2xl hover:text-blue-400 transition-colors"><FaLinkedin /></a>
        </div>

        <footer className="mt-12">
          <p className="text-sm">© Copyright 2024. All Rights Reserved.</p>
        </footer>
      </div>
    </div>
  );
}
