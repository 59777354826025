const API_BASE_URL = 'https://stockforecast-ehfy.onrender.com/emails';

export const subscribeEmail = async (email) => {
  const response = await fetch(`${API_BASE_URL}/subscribe`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.error || 'An error occurred');
  }

  const data = await response.json();
  return data;
};
